<template>
  <van-nav-bar
    v-if="route.path.substr(1) && titleShow"
    :title="titleValue"
    :left-arrow="titleLeft"
    :class="[ isApp ? '' : 'nav-bar']"
    safe-area-inset-top
  >
    <template #left>
      <van-icon v-if="titleLeft" name="arrow-left" />
      <text class="left" v-if="titleLeft" @click="goBack">返回</text>
    </template>
    <template #right>
      <text class="left" v-if="isApp" @click="closeH5">关闭</text>
    </template>
  </van-nav-bar>
  <div :class="[ isApp ? '' : 'margintop']">
    <van-steps class="steps" v-if="stepsShow" active-color="#38f" :active="stepsActive">
      <van-step v-for="item in stepsValue" :key="item">{{item}}</van-step>
    </van-steps>
    <div class="router-view">
      <router-view v-slot="{ Component }">
          <component :is="Component" :key="$route.path"/>
      </router-view>
    </div>
  </div>

</template>
<script>
import {
  NavBar, Icon, Step, Steps,
} from 'vant';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import '@vant/touch-emulator';

export default {
  name: 'Home',
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  watch: {
    $route(to, from) {
      // 有主级到次级
      if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'; // 向左滑动
      } else if (to.meta.index < from.meta.index) {
        // 由次级到主级
        this.transitionName = 'slide-right';
      } else {
        this.transitionName = ''; // 同级无过渡效果
      }
    },
  },
  methods: {
    closeH5() {
      this.$util.callApp('closeWindow?type=AppDark');
    },
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const titleShow = computed(() => store.state.title.titleShow);
    const titleValue = computed(() => store.state.title.titleValue);
    const titleLeft = computed(() => store.state.title.titleLeft);

    const stepsShow = computed(() => store.state.title.stepsShow);
    const stepsValue = computed(() => store.state.title.stepsValue);
    const stepsActive = computed(() => store.state.title.stepsActive);

    const isApp = computed(() => store.state.user.platform === 'APP');
    const goBack = () => router.back();
    return {
      titleShow,
      titleValue,
      titleLeft,
      stepsShow,
      stepsValue,
      stepsActive,
      goBack,
      route,
      isApp,
    };
  },
};
</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow-x: hidden;
  }
  .steps {
    max-width: 500px;
    margin: auto;
    text-align: left;
    box-sizing: border-box;
  }
  .router-view {
    max-width: 500px;
    margin: auto;
  }
  .left {
    color: #1989fa;
    padding: 5px;
  }
  body {
    background-color: @mainbgcolor;
    // padding-top: 46px;
    box-sizing: border-box;
    padding-bottom: 46px;
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  .nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .margintop {
    margin-top: 46px;
  }
  .upload {
    padding: @space-0;
    p{
      text-align: left;
      color: @font-color-1;
    }
    div {
      text-align: left;
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .van-cell-group__title {
    color: @font-color-0 !important;
  }
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active{
      height: 100%;
      will-change: transform;
      transition: all 500ms;
      position: absolute;
      backface-visibility: hidden;
  }
  .slide-right-enter{
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
  }
  .slide-right-leave-active{
      opacity: 0;
      transform: translate3d(100%, 0, 0);
  }
  .slide-left-enter{
      opacity: 0;
      transform: translate3d(100%, 0, 0);
  }
  .slide-left-leave-active{
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
  }
  .link {
    color: #1989fa;
    cursor: pointer;
  }
  .van-field--disabled .van-field__label {
    color: #646566 !important;
  }
</style>
