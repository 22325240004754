export default {
  namespace: true,
  state: {
    isLogin: false,
    cookiePhone: '',
    cookieExpires: '',
    isDark: false, // 主体模式 是否黑色
    platform: '',
    isAddAccount: false, // 判断是否补充开户
    isEnterAccount: false,
    isEnterMajor: false,
    yearlyRiskState: -1, // 判断是否补充风险评估更新
    isYearlyRisk: false, // 是否正在更新风险评估
  },
  mutations: {
    setIsLogin(state, { value, cb }) {
      state.isLogin = value;
      if (typeof cb === 'function') {
        cb();
      }
    },
    loginOut(state) {
      state.isLogin = false;
    },
    setCookiePhone(state, payload) {
      state.cookiePhone = payload;
    },
    setCookieExpires(state, payload) {
      state.cookieExpires = payload;
    },
    setDark(state, payload = true) {
      state.isDark = payload;
    },
    setPlatform(state, payload = '') {
      state.platform = payload;
    },
    setIsAddAccount(state, payload = true) {
      state.isAddAccount = payload;
      if (payload) state.isYearlyRisk = false; // 互斥， 同时只能补开一个
    },
    setIsEnterAccount(state, payload = true) {
      state.isEnterAccount = payload;
    },
    setIsEnterMajor(state, payload = true) {
      state.isEnterMajor = payload;
    },
    setIsYearlyRisk(state, payload = true) {
      state.isYearlyRisk = payload;
      if (payload) state.isAddAccount = false; // 互斥， 同时只能补开一个
    },
    setYearlyRiskState(state, payload = null) {
      state.yearlyRiskState = payload;
    },
  },
  actions: {
    handleLogin(ctx, cb) {
      ctx.commit('setIsLogin', { value: true, cb });
    },
  },
};
