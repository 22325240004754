// import { Post, PostForm, PostFile, PostJSON } from './http';
import axios from 'axios';
import { Toast } from 'vant';
import { PostFile, PostJSON } from './http';

/** 输入手机号 */
const sendVerCode = (mobile) => PostJSON('/messenger/openAccount/getVerificationCode.json', { mobile }, { loading: false, title: '发送手机号' });
/** 输入手机号 */
const login = (mobile, verificationCode) => PostJSON('/messenger/openAccount/login.json', { mobile, verificationCode }, { title: '发送手机号、验证码' });
/** 登陆 ISDA */
const sendIsdaVerCode = (mobile) => PostJSON('/messenger/trs/getVerificationCode.json', { mobile }, { loading: false, title: '发送手机号' });
const loginIsda = (mobile, verificationCode) => PostJSON('/messenger/trs/login.json', { mobile, verificationCode }, { title: '发送isda手机号、验证码' });
/** 选择账户 */
const getChooseStepOne = () => PostJSON('/messenger/openAccount/getAccountAgreementStatus.json', {}, { title: '获取选择账户数据' });
const postChooseStepOne = (form) => PostJSON('/messenger/openAccount/updateAccountAgreementStatus.json', form, { title: '提交选择账户数据' });
/** 第一步资料 */
const getInfoStepOne = () => PostJSON('/messenger/openAccount/getBasicInfo.json', {}, { title: '初始化基本资料第一步数据' });
const postInfoStepOne = (form) => PostJSON('/messenger/openAccount/updateBasicInfo.json', form, { title: '提交基本资料第一步数据' });
/** 第二步资料 */
const getInfoStepTwo = () => PostJSON('/messenger/openAccount/getWorkAndFinanceInfo.json', {}, { title: '初始化基本资料第二步数据' });
const postInfoStepTwo = (form) => PostJSON('/messenger/openAccount/updateWorkAndFinanceInfo.json', form, { title: '提交基本资料第二步数据' });
/** 第三步资料 */
const getInfoStepThree = () => PostJSON('/messenger/openAccount/getInvestmentObjectiveAndExperience.json', {}, { title: '初始化基本资料第三步数据' });
const postInfoStepThree = (form) => PostJSON('/messenger/openAccount/updateInvestmentObjectiveAndExperience.json', form, { title: '提交基本资料第三步数据' });
/** 第四步资料 */
const getInfoStepFour = () => PostJSON('/messenger/openAccount/getOtherInfo.json', {}, { title: '初始化基本资料第四步数据' });
const postInfoStepFour = (form) => PostJSON('/messenger/openAccount/updateOtherInfo.json', form, { title: '提交基本资料第四步数据' });
/** 第五步资料 */
const getInfoStepFive = () => PostJSON('/messenger/openAccount/getTaxInfo.json', {}, { title: '初始化基本资料第五步数据' });
const postInfoStepFive = (form) => PostJSON('/messenger/openAccount/updateTaxInfo.json', form, { title: '提交基本资料第五步数据' });

/** 风险认知首页 */
const getAsessIndex = () => PostJSON('/messenger/openAccount/getDerivativeAssessmentChoices.json', {}, { title: '初始化风险认知首页数据' });
const postAsessIndex = (form) => PostJSON('/messenger/openAccount/updateDerivativeAssessmentChoices.json', form, { title: '提交风险认知首页数据' });
/** 风险认知第一步 */
const getAsessStepOne = () => PostJSON('/messenger/openAccount/getDerivativeKnowledgeAssessmentChoices.json', {}, { title: '初始化风险认知第一步数据' });
const postAsessStepOne = (form) => PostJSON('/messenger/openAccount/updateDerivativeKnowledgeAssessmentChoices.json', form, { title: '提交风险认知第一步数据' });
/** 专业投资者选项第一步 */
const getMajorOptionStepOne = () => PostJSON('/messenger/openAccount/getMajorInvesterInfo.json', {}, { title: '初始化专业投资者第一步数据' });
const postMajorOptionStepOne = (form) => PostJSON('/messenger/openAccount/updateMajorInvesterInfo.json', form, { title: '提交专业投资者第一步数据' });
/** 专业投资者选项第二步 */
const getMajorOptionStepTwo = () => PostJSON('/messenger/openAccount/getMajorAssessInfo.json', {}, { title: '初始化专业投资者第二步数据' });
const postMajorOptionStepTwo = (form) => PostJSON('/messenger/openAccount/updateMajorAssessInfo.json', form, { title: '提交专业投资者第二步数据' });
/** 专业投资者选项第三步 */
const getMajorOptionStepThree = () => PostJSON('/messenger/openAccount/getMajorConfirmInfo.json', {}, { title: '初始化专业投资者第三步数据' });
const postMajorOptionStepThree = (form) => PostJSON('/messenger/openAccount/updateMajorConfirmInfo.json', form, { title: '提交专业投资者第三步数据' });
/** 判断进入下一步 */
const getHasFinishedRiskTropism = () => PostJSON('/messenger/openAccount/hasFinishedRiskTropism.json', {}, { loading: false, title: '判断是否已完成投资风险取向评测' });
/** 投资风险取向第一步 */
const getRiskOrientationStepOne = () => PostJSON('/messenger/openAccount/getRiskTropism.json', {}, { title: '初始化投资风险取向第一步数据' });
const postRiskOrientationStepOne = (form) => PostJSON('/messenger/openAccount/updateRiskTropism.json', form, { title: '提交投资风险取向第一步数据' });
/** 投资风险取向第二步 */
const getRiskOrientationStepTwo = () => PostJSON('/messenger/openAccount/getRiskTropismAssessResult.json', {}, { title: '初始化投资风险取向第二步数据' });
/** 投资风险取向第三步 */
const getRiskOrientationStepThree = () => PostJSON('/messenger/openAccount/getRiskConfirmInfo.json', {}, { title: '初始化投资风险取向第三步数据' });
const postRiskOrientationStepThree = (form) => PostJSON('/messenger/openAccount/updateRiskConfirmInfo.json', form, { title: '提交投资风险取向第三步数据' });
/** 风险披露 */
const getRiskDisclosurStepOne = () => PostJSON('/messenger/openAccount/getRiskTellStatus.json', {}, { title: '初始化风险披露状态' });
const postRiskDisclosurStepOne = (form) => PostJSON('/messenger/openAccount/updateRiskTellStatus.json', form, { title: '提交风险披露状态' });
const getVideoType = () => PostJSON('/messenger/openAccount/getVideoType.json', {}, { title: '获取编号' });
/** 风险披露签名 */
const getRiskDisclosurStepTwo = () => PostJSON('/messenger/openAccount/getRiskTellSign.json', {}, { title: '初始化风险披露签名' });
const postRiskDisclosurStepTwo = (form) => PostJSON('/messenger/openAccount/updateRiskTellSign.json', form, { title: '提交风险披露签名' });
/** 上传图片 */
const getUploadImgesStatus = () => PostJSON('/messenger/openAccount/getImageResourcesStatus.json', {}, { title: '获取是否已上传各电子副本' });
const getUploadImgesByType = (form) => PostJSON('/messenger/openAccount/getImageResource.json', form, { title: '按类型获取各电子副本' });
const postUploadImgesByType = (form) => PostJSON('/messenger/openAccount/updateImageResource.json', form, { title: '按类型上传各电子副本' });
const getAccountSubmit = () => PostJSON('/messenger/openAccount/submitInfo.json', {}, { title: '提交' });
const getApproveStatus = () => PostJSON('/messenger/openAccount/getApproveStatus.json', {}, { title: '查询审批进度' });
const getOpenAccountInfo = () => PostJSON('/messenger/openAccount/getOpenAccountInfo.json', {}, { title: '获取开户信息接口' });
const submitAddressInfo = (form) => PostJSON('/messenger/openAccount/submitAddressInfo.json', form, { title: '提交地址信息' });
const uploadVideo = (form) => PostFile('/messenger/openAccount/uploadVideo.json', form, { title: '上传视频' });
const getVideoCode = () => PostJSON('/messenger/openAccount/getVideoCode.json', {}, { title: '获取随机数' });
/** ISDA */
const getIsdaState = () => PostJSON('/messenger/isda/getIsdaState.json', {}, { title: 'ISDA协议状态查询接口' });
const getIsdaStateNoLog = () => PostJSON('/messenger/isda/getTrsState.json', {}, { title: 'ISDA协议查询 没有日志' });
const getAgreementState = () => PostJSON('/messenger/isda/getAgreementState.json', {}, { title: '获取阅读ISDA协议的状态' });
const postAgreementState = (form) => PostJSON('/messenger/isda/submitAgreementState.json', form, { title: '提交阅读ISDA协议的状态' });
const getIsdaAccounts = (form) => PostJSON('/messenger/isda/getIsdaAccounts.json', form, { title: '获取ISDA协议账户信息' });
const postIsdaAccounts = (form) => PostJSON('/messenger/isda/submitIsdaAccounts.json', form, { title: '提交ISDA协议账户申请' });
/** 每年风险评估 */
const getYearlyRiskTropism = () => PostJSON('/messenger/yearly/getRiskTropism.json', {}, { title: '获取投资风险取向试题与已选答案' });
const postYearlyRiskTropism = (form) => PostJSON('/messenger/yearly/updateRiskTropism.json', form, { title: '提交投资风险取向答案' });
const getYearlyRiskTropismAssessResult = () => PostJSON('/messenger/yearly/getRiskTropismAssessResult.json', {}, { title: '获取投资风险评估结果' });
const getYearlyRiskTropismInfo = () => PostJSON('/messenger/yearly/getRiskTropismInfo.json', {}, { title: '获取风险更新审批信息' });
const submitRiskTropismState = () => PostJSON('/messenger/yearly/submitRiskTropismState.json', {}, { title: '提交风险认知状态' });
/** 开立子账户 */
const getSubAccountState = () => PostJSON('/messenger/subAccount/getSubAccountState.json', {}, { title: '开立子账号状态查询接口' });
const getSubAccountInfo = () => PostJSON('/messenger/subAccount/getSubAccountInfo.json', {}, { title: '获取开立子账号信息接口' });
const submitSubAccount = (form) => PostJSON('/messenger/subAccount/submitSubAccount.json', form, { title: '提交子账号申请' });
/** 提交日志 */
const submitLog = (form) => PostJSON('/messenger/openAccount/submitLog.json', form, { title: '提交ISDA协议账户申请', loading: false });
/** 上传PDF */
const uploadPdf = (type, form) => {
  const oneMb = 1024 * 1024;
  if (form.get('file').size / oneMb >= 5) {
    Toast('请上传5MB以内的pdf文件');
    return null;
  }
  return PostFile(`/messenger/openAccount/uploadPdf.json?type=${type}`, form, { title: '上传PDF' });
};
const postDownLoadFile = async (url, name, witherr = false) => {
  if (witherr) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 30000,
    });
    try {
      const { data: result } = await axios.get(url);
      if (result && result.code === 201) {
        const { msg = '下载失败' } = result;
        Toast.clear();
        Toast(msg);
        return;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    Toast.clear();
  }
  Toast.loading({
    message: '下载中...',
    forbidClick: true,
    duration: 30000,
  });
  axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
    const blobType = res.headers['content-type'];
    // 获取文件名
    let filename = name;
    const disposition = res.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);

      if (matches != null && matches[1]) {
        filename = window.decodeURI(matches[1].replace(/['"]/g, ''));
      }
    }
    const blob = new Blob([res.data], {
      type: blobType,
    });
    const objectUrl = URL.createObjectURL(blob);
    const downEle = document.createElement('a');
    downEle.href = objectUrl;
    downEle.setAttribute('download', filename);
    document.body.appendChild(downEle);
    downEle.click();
    document.body.removeChild(downEle);
  }).catch(() => {
    Toast('网络错误');
  }).finally(() => {
    Toast.clear();
  });
};

const getDisabled = async () => {
  try {
    const { data = {} } = await PostJSON('/messenger/openAccount/getEditable.json', {}, { loading: false });
    return !data.editable;
  } catch (error) {
    return true;
  }
};
const getIsdaDisabled = async () => {
  try {
    const { data = {} } = await PostJSON('/messenger/isda/getTrsState.json', {}, { loading: false });
    return !(data.isda_state === 0 || data.isda_state === 4);
  } catch (error) {
    return true;
  }
};

/** 增加/修改手机号命令 */
const addNewAccount = (form) => PostJSON('/setVCode', form, { title: '增加手机账号' });
/** 增加/修改手机号数据 */
const clearAccount = (form) => PostJSON('/cleanData', form, { title: '增加手机账号' });

/** 下载文件 */
const download = (url, name) => axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
  if (res.status === 200) {
    const blob = new Blob([res.data], {
      type: 'application/pdf;charset=UTF-8',
    });
    const objectUrl = URL.createObjectURL(blob);
    const downEle = document.createElement('a');
    downEle.href = objectUrl;
    downEle.setAttribute('download', name);
    document.body.appendChild(downEle);
    downEle.click();
  }
}).catch(() => {
  Toast('网络错误');
});
const formLog = () => {
  // if (!localStorage.getItem('page-data')) return;
  // console.group('页面数据');
  // if (form) {
  //   console.log('%c 页面提交数据变化了 : ', 'color:#06ae56;', JSON.parse(JSON.stringify(form)));
  // }
  // if (!disabled.value) {
  //   console.log('按钮不禁用');
  // } else {
  //   console.log('%c 按钮禁用', 'color:red;');
  // }
  // console.groupEnd('页面数据');
};

const log = () => {
  // console.log(`%c${tip}`, 'color:#06ae56;', value);
};

export default {
  formLog,
  log,
  sendVerCode,
  login,
  sendIsdaVerCode,
  loginIsda,
  getInfoStepOne,
  postInfoStepOne,
  getInfoStepTwo,
  postInfoStepTwo,
  getInfoStepThree,
  postInfoStepThree,
  getInfoStepFour,
  postInfoStepFour,
  getInfoStepFive,
  postInfoStepFive,
  getAsessIndex,
  postAsessIndex,
  getAsessStepOne,
  getVideoType,
  postAsessStepOne,
  getMajorOptionStepOne,
  postMajorOptionStepOne,
  getMajorOptionStepTwo,
  postMajorOptionStepTwo,
  getMajorOptionStepThree,
  postMajorOptionStepThree,
  getHasFinishedRiskTropism,
  getRiskOrientationStepOne,
  postRiskOrientationStepOne,
  getRiskOrientationStepTwo,
  getRiskOrientationStepThree,
  postRiskOrientationStepThree,
  getRiskDisclosurStepOne,
  postRiskDisclosurStepOne,
  getRiskDisclosurStepTwo,
  postRiskDisclosurStepTwo,
  getUploadImgesStatus,
  getUploadImgesByType,
  postUploadImgesByType,
  getAccountSubmit,
  getApproveStatus,
  getChooseStepOne,
  postChooseStepOne,
  addNewAccount,
  clearAccount,
  download,
  getDisabled,
  getOpenAccountInfo,
  submitAddressInfo,
  uploadVideo,
  getVideoCode,
  // isda
  getIsdaState,
  getIsdaStateNoLog,
  getAgreementState,
  postAgreementState,
  getIsdaAccounts,
  postIsdaAccounts,
  getIsdaDisabled,
  submitLog,

  getSubAccountState,
  getSubAccountInfo,
  submitSubAccount,

  getYearlyRiskTropism,
  postYearlyRiskTropism,
  getYearlyRiskTropismAssessResult,
  getYearlyRiskTropismInfo,
  submitRiskTropismState,

  uploadPdf,
  postDownLoadFile,
};
